/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { GetAgentsResponse } from '../../models/get-agents-response';

export interface GetAgentList$Params {

/**
 * Meno agenta
 */
  firstName?: string;

/**
 * priezvisko agenta
 */
  lastName?: string;

/**
 * názov spoločnosti
 */
  companyName?: string;

/**
 * tel. číslo
 */
  phoneNumber?: string;

/**
 * email
 */
  email?: string;

/**
 * Číslo stránky
 */
  page?: number;

/**
 * Počet záznamov na stránku
 */
  size?: number;

/**
 * Zoznam atribútov pre zoraďovanie v tvare **column,column**
 */
  sort?: string;

/**
 * Smer zoraďovania atribútov
 */
  direction?: 'ASC' | 'DESC';
}

export function getAgentList(http: HttpClient, rootUrl: string, params?: GetAgentList$Params, context?: HttpContext): Observable<StrictHttpResponse<GetAgentsResponse>> {
  const rb = new RequestBuilder(rootUrl, getAgentList.PATH, 'get');
  if (params) {
    rb.query('firstName', params.firstName, {});
    rb.query('lastName', params.lastName, {});
    rb.query('companyName', params.companyName, {});
    rb.query('phoneNumber', params.phoneNumber, {});
    rb.query('email', params.email, {});
    rb.query('page', params.page, {});
    rb.query('size', params.size, {});
    rb.query('sort', params.sort, {});
    rb.query('direction', params.direction, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<GetAgentsResponse>;
    })
  );
}

getAgentList.PATH = '/admin/agents';
