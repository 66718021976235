/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { GetCompaniesResponse } from '../../models/get-companies-response';

export interface GetCompanyList$Params {

/**
 * Názov spoločnosti
 */
  name?: string;

/**
 * IČO spoločnosti
 */
  taxNumber?: string;

/**
 * Mesto spoločnosti
 */
  addressCity?: string;

/**
 * validita
 */
  recordValidity?: string;

/**
 * email
 */
  infoSendingEmail?: string;

/**
 * Číslo stránky
 */
  page?: number;

/**
 * Počet záznamov na stránku
 */
  size?: number;

/**
 * Zoznam atribútov pre zoraďovanie v tvare **column,column**
 */
  sort?: string;

/**
 * Smer zoraďovania atribútov
 */
  direction?: 'ASC' | 'DESC';
}

export function getCompanyList(http: HttpClient, rootUrl: string, params?: GetCompanyList$Params, context?: HttpContext): Observable<StrictHttpResponse<GetCompaniesResponse>> {
  const rb = new RequestBuilder(rootUrl, getCompanyList.PATH, 'get');
  if (params) {
    rb.query('name', params.name, {});
    rb.query('taxNumber', params.taxNumber, {});
    rb.query('addressCity', params.addressCity, {});
    rb.query('recordValidity', params.recordValidity, {});
    rb.query('infoSendingEmail', params.infoSendingEmail, {});
    rb.query('page', params.page, {});
    rb.query('size', params.size, {});
    rb.query('sort', params.sort, {});
    rb.query('direction', params.direction, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<GetCompaniesResponse>;
    })
  );
}

getCompanyList.PATH = '/admin/companies';
